import {
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
  ORDER_HISTORY_FAILURE,
  ORDER_SELECT_ALL,
  ORDER_SELECT,
  ORDER_CHANGE_PAGE,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAILURE,
  GET_ORDER_BY_USER_FILTER_REQUEST,
  GET_ORDER_BY_USER_FILTER_SUCCESS,
  GET_FILE_NUMBERS_REQUEST,
  GET_FILE_NUMBERS_SUCCESS,
  GET_FILE_NUMBERS_FAILURE,
  ORDER_HISTORY_FORM,
  ORDER_HISTORY_CLEAR,
  GET_APPLICANTS_REQUEST,
  GET_APPLICANTS_SUCCESS,
  GET_APPLICANTS_FAILURE,
  GET_MANUFACTURERS_REQUEST,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_FAILURE,
  GET_ORACLE_NUMBERS_REQUEST,
  GET_ORACLE_NUMBERS_SUCCESS,
  GET_ORACLE_NUMBERS_FAILURE,
  GET_REQUEST_NUMBERS_REQUEST,
  GET_REQUEST_NUMBERS_SUCCESS,
  GET_REQUEST_NUMBERS_FAILURE,
  SET_USER_FILTER_REQUEST,
  SET_USER_FILTER_SUCCESS,
  SET_USER_FILTER_FAILURE,
  GET_USER_FILTER_REQUEST,
  GET_USER_FILTER_SUCCESS,
  GET_USER_FILTER_FAILURE,
  FILTEROPTION_RESET,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_LABEL_CENTERS_REQUEST,
  GET_LABEL_CENTERS_SUCCESS,
  GET_LABEL_CENTERS_FAILURE,
  GET_LABEL_APPLICANT_ACCOUNTNO_REQUEST,
  GET_LABEL_APPLICANT_ACCOUNTNO_FAILURE,
  GET_LABEL_MANUFACTURER_ACCOUNTNO_REQUEST,
  GET_LABEL_MANUFACTURER_ACCOUNTNO_SUCCESS,
  GET_LABEL_MANUFACTURER_ACCOUNTNO_FAILURE,
  GET_LABEL_APPLICANT_ACCOUNTNO_SUCCESS,
  ORDER_HISTORY_UPDATE,
  UPLOAD_LABEL_ORDER_ATTACHMENT_REQUEST,
  UPLOAD_LABEL_ORDER_ATTACHMENT_FAILURE,
  UPLOAD_LABEL_ORDER_ATTACHMENT_SUCCESS,
  DELETE_LABEL_ORDER_ATTACHMENT_SUCCESS,
  DELETE_LABEL_ORDER_ATTACHMENT_FAILURE,
  DELETE_LABEL_ORDER_ATTACHMENT_REQUEST,
  GET_LABEL_ORDER_ATTACHMENT_REQUEST,
  GET_LABEL_ORDER_ATTACHMENT_FAILURE,
  GET_LABEL_ORDER_ATTACHMENT_SUCCESS,
  SEND_ATTACHMENT_MESSAGE_REQUEST,
  SEND_ATTACHMENT_MESSAGE_FAILURE,
  SEND_ATTACHMENT_MESSAGE_SUCCESS,
  GET_CUSTOMER_PO_NUMBER_REQUEST,
  GET_CUSTOMER_PO_NUMBER_FAILURE,
  GET_CUSTOMER_PO_NUMBER_SUCCESS,
  GET_CUSTOMER_CONTACT_NAME_REQUEST,
  GET_CUSTOMER_CONTACT_NAME_FAILURE,
  GET_CUSTOMER_CONTACT_NAME_SUCCESS,
  GET_APPLICANT_ADDRESS_REQUEST,
  GET_APPLICANT_ADDRESS_FAILURE,
  GET_APPLICANT_ADDRESS_SUCCESS,
  GET_MANUFACTURER_ADDRESS_REQUEST,
  GET_MANUFACTURER_ADDRESS_FAILURE,
  GET_MANUFACTURER_ADDRESS_SUCCESS,
  GET_MANUFACTURER_PSN_REQUEST,
  GET_MANUFACTURER_PSN_FAILURE,
  GET_MANUFACTURER_PSN_SUCCESS,
  UPDATE_BUBBLE_STATUS_REQUEST,
  UPDATE_BUBBLE_STATUS_FAILURE,
  UPDATE_BUBBLE_STATUS_SUCCESS,
  CLEAR_EXPORT_SELECTION,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_REQUEST_SUCCESS,
  CREATE_REPORT_REQUEST_FAILURE,
  GET_REPORT_LINK_REQUEST,
  GET_REPORT_LINK_REQUEST_SUCCESS,
  GET_REPORT_LINK_REQUEST_FAILURE,
  CLOSE_REPORT_REQUEST_DIALOG,
  CLOSE_REPORT_LINK_DIALOG,
  CLOSE_CANNOT_COPY_DALOG,
  CLOSE_COPY_NOT_COMPLIED_DIALOG,
  OPEN_CANNOT_COPY_DIALOG,
  OPEN_COPY_NOT_COMPLIED_DIALOG,
  OPEN_EXISTING_ITEM_IN_CART_DIALOG,
  CLOSE_EXISTING_ITEM_IN_CART_DIALOG,
  GET_CART_AND_ORDER_DETAILS,
  GET_CART_AND_ORDER_DETAILS_SUCCESS,
  GET_CART_AND_ORDER_DETAILS_FAILURE,
  MANUAL_REPUSH,
  MANUAL_REPUSH_SUCCESS,
  MANUAL_REPUSH_FAILURE,
  OPEN_AUTO_SWITCH_PSN_ROLE_DIALOG,
  CLOSE_AUTO_SWITCH_PSN_ROLE_DIALOG,
  CLEAR_FILTER_TRIGGER,
  CLEAR_FILTER_TRIGGER_RESET,
  DISABLE_CLEAR_FILTER,
  IS_VALID_COPY_ORDER_REQUEST,
  IS_VALID_COPY_ORDER_SUCCESS,
  IS_VALID_COPY_ORDER_FAILURE,
} from './view-orders.type';
import _ from 'lodash';

const initialState = {
  hasError: false,
  message: '',
  loading: false,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  orders: null,
  details: null,
  filter: null,
  isSelectedAll: false,
  isFiltersExpanded: false,
  isExportEnabled: true,
  isShowReportRequestModal: false,
  isShowExportLink: false,
  reportLinkGenerated: '',
  isSaveUserFilterSuccess: false,
  saveUserFilterLoading: false,
  isGetUserFilterSuccess: false,
  getUserFilterLoading: false,
  statuses: [],
  statusOptions: [],
  fileNumbers: [],
  fileNumbersOption: [],
  applicants: [],
  applicantsOption: [],
  manufacturers: [],
  manufacturersOption: [],
  oracleOrderNumbers: [],
  oracleOracleNumbersOption: [],
  requestNumbers: [],
  requestNumbersOption: [],
  labelCenters: [],
  labelCentersOption: [],
  applicantAccountNos: [],
  applicantAccountNosOption: [],
  manufacturerAccountNos: [],
  manufacturerAccountNosOption: [],
  form: {
    showErrors: false,
    validationErrors: {},
  },
  uploading: false,
  isUploadAttachmentSuccess: false,
  isDeleteAttachmentSuccess: false,
  isSendAttachmentMessageSuccess: false,
  deleting: false,
  sending: false,
  attachments: [],
  customerPoNos: [],
  customerPoNosOption: [],
  customerContactNames: [],
  customerContactNamesOption: [],
  applicantAddress: [],
  applicantAddressOption: [],
  manufacturerAddress: [],
  manufacturerAddressOption: [],
  manufacturerPsn: [],
  manufacturerPsnOption: [],
  isUpdateBubbleStatusSuccess: false,
  selectedOrderNumbers: [],
  selectedEmailSentOption: 'All',
  showCannotCopyModal: false,
  showCopyNotCompliedModal: false,
  showExistingItemInCartModal: false,
  copyOrderCheckStatus: 0,
  isManualRepushFailed: false,
  showAutoSwitchPsnRoleDialog: false,
  autoSwitchRoleData: {},
  isClearFilterToggled: false,
  isValidCopyOrder: false,
};

const viewOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_HISTORY_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
        getUserFilterLoading: true,
      };
    case ORDER_HISTORY_SUCCESS:
      const result = {
        ...state,
        hasError: false,
        orders: action.payload.data,
        totalPages: action.payload.totalPages,
        loading: false,
        getUserFilterLoading: false,
      };

      const groupOrders = _(result.orders)
        .groupBy(item => item.requestNumber)
        .sortBy(group => group.oracleNumber)
        .value();

      const newOrders = groupOrders.map(i => {
        i[0].childItems = i.slice(1);
        return i[0];
      });

      result.orders = newOrders;
      result.currentPage =
        action.payload.totalPages / result.pageSize >= 1 ? 1 : 0;
      return result;
    case ORDER_HISTORY_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
        getUserFilterLoading: false,
      };
    case GET_ORDER_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_ORDER_SUCCESS:
      const getOrderResult = {
        ...state,
        hasError: false,
        orders: action.payload.data,
        totalPages: action.payload.totalPages,
        loading: false,
      };

      const getOrderGroups = _(getOrderResult.orders)
        .groupBy(item => item.requestNumber)
        .sortBy(group => group.oracleNumber)
        .value();

      const newOrderList = getOrderGroups.map(i => {
        i[0].childItems = i.slice(1);
        return i[0];
      });

      getOrderResult.orders = newOrderList;
      return getOrderResult;
    case GET_ORDER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case ORDER_CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload.activePage,
        isSelectedAll: false,
        selectedOrderNumbers: [],
      };
    case ORDER_SELECT:
      const orderSelectState = {
        ...state,
        loading: false,
      };
      const { data, isSelected } = action.payload;
      const idx = orderSelectState.selectedOrderNumbers.indexOf(
        data.requestNumber
      );
      if (isSelected) {
        if (idx < 0) {
          orderSelectState.selectedOrderNumbers.push(data.requestNumber);
        }
      } else {
        orderSelectState.isSelectedAll = false;
        if (idx >= 0) {
          orderSelectState.selectedOrderNumbers.splice(idx, 1);
        }
      }
      if (orderSelectState.orders) {
        orderSelectState.isSelectedAll = orderSelectState.orders.every(o => {
          return (
            orderSelectState.selectedOrderNumbers.indexOf(o.requestNumber) !==
            -1
          );
        });
      }
      orderSelectState.isExportEnabled =
        orderSelectState.selectedOrderNumbers != null &&
        orderSelectState.selectedOrderNumbers.length > 0;
      return orderSelectState;
    case ORDER_SELECT_ALL:
      const orderSelectAllState = {
        ...state,
        isSelectedAll: action.payload.isSelectedAll,
        loading: false,
      };
      if (orderSelectAllState.isSelectedAll) {
        orderSelectAllState.selectedOrderNumbers =
          orderSelectAllState.orders.map(order => {
            return order.requestNumber;
          });
      } else {
        orderSelectAllState.selectedOrderNumbers = [];
      }
      orderSelectAllState.orders = orderSelectAllState.orders.map(order => {
        return {
          ...order,
          isSelected: orderSelectAllState.isSelectedAll ? true : false,
        };
      });
      orderSelectAllState.isSelectedAll = action.payload.isSelectedAll;
      orderSelectAllState.isExportEnabled = action.payload.isSelectedAll;
      return orderSelectAllState;
    case CLEAR_EXPORT_SELECTION:
      const clearExportState = {
        ...state,
      };
      clearExportState.selectedOrderNumbers = [];
      clearExportState.isSelectedAll = false;
      clearExportState.isExportEnabled = false;
      return clearExportState;
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        hasError: false,
        details: action.payload,
        loading: false,
      };
    case ORDER_DETAILS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_ORDER_BY_USER_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_BY_USER_FILTER_SUCCESS:
      return {
        ...state,
        hasError: false,
        orders: action.payload.data || initialState.orders,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    case GET_FILE_NUMBERS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_FILE_NUMBERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        fileNumbers: action.payload.data,
        fileNumbersOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_FILE_NUMBERS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_APPLICANTS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_APPLICANTS_SUCCESS:
      return {
        ...state,
        hasError: false,
        applicants: action.payload.data,
        applicantsOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_APPLICANTS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_MANUFACTURERS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        manufacturers: action.payload.data,
        manufacturersOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_MANUFACTURERS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_ORACLE_NUMBERS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_ORACLE_NUMBERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        oracleOrderNumbers: action.payload.data,
        oracleOracleNumbersOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_ORACLE_NUMBERS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_REQUEST_NUMBERS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_REQUEST_NUMBERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        requestNumbers: action.payload.data,
        requestNumbersOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_REQUEST_NUMBERS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_LABEL_CENTERS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_LABEL_CENTERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        labelCenters: action.payload,
        labelCentersOption: action.payload.map(i => {
          return {
            key: i.id,
            value: i.name,
            text: i.name,
          };
        }),
        loading: false,
      };
    case GET_LABEL_CENTERS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_LABEL_APPLICANT_ACCOUNTNO_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_LABEL_APPLICANT_ACCOUNTNO_SUCCESS:
      return {
        ...state,
        hasError: false,
        applicantAccountNos: action.payload.data,
        applicantAccountNosOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_LABEL_APPLICANT_ACCOUNTNO_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_LABEL_MANUFACTURER_ACCOUNTNO_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_LABEL_MANUFACTURER_ACCOUNTNO_SUCCESS:
      return {
        ...state,
        hasError: false,
        manufacturerAccountNos: action.payload.data,
        manufacturerAccountNosOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_LABEL_MANUFACTURER_ACCOUNTNO_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case SET_USER_FILTER_REQUEST:
      return {
        ...state,
        saveUserFilterLoading: true,
      };
    case SET_USER_FILTER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSaveUserFilterSuccess:
          action.payload.isSuccessful || initialState.isSaveUserFilterSuccess,
        getUserFilterLoading: false,
      };
    case SET_USER_FILTER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isSaveUserFilterSuccess: false,
        saveUserFilterLoading: false,
      };
    case GET_USER_FILTER_REQUEST:
      return {
        ...state,
        getUserFilterLoading: true,
      };
    case GET_USER_FILTER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isGetUserFilterSuccess:
          action.payload.isSuccessful || initialState.isGetUserFilterSuccess,
        filter: action.payload.data || initialState.filter,
        getUserFilterLoading: false,
      };
    case GET_USER_FILTER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isGetUserFilterSuccess: false,
        saveUserFilterLoading: false,
      };
    case FILTEROPTION_RESET:
      return {
        ...state,
        loading: false,
        isGetUserFilterSuccess: false,
        isSaveUserFilterSuccess: false,
      };
    case ORDER_HISTORY_UPDATE:
      return {
        ...state,
        orders: action.payload || initialState.orders,
      };
    case ORDER_HISTORY_FORM:
      return {
        ...state,
        form: action.payload,
        hasError: true,
        loading: false,
      };
    case ORDER_HISTORY_CLEAR:
      return {
        ...state,
        form: {
          showErrors: false,
          validationErrors: {},
        },
        hasError: true,
        loading: false,
      };
    case UPLOAD_LABEL_ORDER_ATTACHMENT_REQUEST:
      return {
        ...state,
        uploading: true,
      };
    case UPLOAD_LABEL_ORDER_ATTACHMENT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isUploadAttachmentSuccess:
          action.payload.isSuccessful || initialState.isSaveUserFilterSuccess,
        uploading: false,
      };
    case UPLOAD_LABEL_ORDER_ATTACHMENT_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        uploading: false,
        isUploadAttachmentSuccess: false,
      };
    case DELETE_LABEL_ORDER_ATTACHMENT_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_LABEL_ORDER_ATTACHMENT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isDeleteAttachmentSuccess: action.payload.isSuccessful,
        deleting: false,
      };
    case DELETE_LABEL_ORDER_ATTACHMENT_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        deleting: false,
        isDeleteAttachmentSuccess: false,
      };
    case SEND_ATTACHMENT_MESSAGE_REQUEST:
      return {
        ...state,
        sending: true,
      };
    case SEND_ATTACHMENT_MESSAGE_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSendAttachmentMessageSuccess: action.payload.isSuccessful,
        sending: false,
      };
    case SEND_ATTACHMENT_MESSAGE_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        sending: false,
        isSendAttachmentMessageSuccess: false,
      };
    case GET_LABEL_ORDER_ATTACHMENT_REQUEST:
      return {
        ...state,
        uploading: true,
      };
    case GET_LABEL_ORDER_ATTACHMENT_SUCCESS:
      return {
        ...state,
        hasError: false,
        attachments: action.payload,
        uploading: false,
      };
    case GET_LABEL_ORDER_ATTACHMENT_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        uploading: false,
        isUploadAttachmentSuccess: false,
      };
    case GET_CUSTOMER_PO_NUMBER_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_CUSTOMER_PO_NUMBER_SUCCESS:
      return {
        ...state,
        hasError: false,
        customerPoNos: action.payload.data,
        customerPoNosOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_CUSTOMER_PO_NUMBER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_CUSTOMER_CONTACT_NAME_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_CUSTOMER_CONTACT_NAME_SUCCESS:
      return {
        ...state,
        hasError: false,
        customerContactNames: action.payload.data,
        customerContactNamesOption: action.payload.data.map(i => {
          return {
            key: i.contactName,
            value: i.contactName,
            text: i.contactName,
          };
        }),
        loading: false,
      };
    case GET_CUSTOMER_CONTACT_NAME_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_APPLICANT_ADDRESS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_APPLICANT_ADDRESS_SUCCESS:
      return {
        ...state,
        hasError: false,
        applicantAddress: action.payload.data,
        applicantAddressOption: action.payload.data.map(i => {
          return {
            key: i.applicantAddress,
            value: i.applicantAddress,
            text: i.applicantAddress,
          };
        }),
        loading: false,
      };
    case GET_APPLICANT_ADDRESS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_MANUFACTURER_ADDRESS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_MANUFACTURER_ADDRESS_SUCCESS:
      return {
        ...state,
        hasError: false,
        manufacturerAddress: action.payload.data,
        manufacturerAddressOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_MANUFACTURER_ADDRESS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case GET_MANUFACTURER_PSN_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_MANUFACTURER_PSN_SUCCESS:
      return {
        ...state,
        hasError: false,
        manufacturerPsn: action.payload.data,
        manufacturerPsnOption: action.payload.data.map(i => {
          return {
            key: i,
            value: i,
            text: i,
          };
        }),
        loading: false,
      };
    case GET_MANUFACTURER_PSN_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case UPDATE_BUBBLE_STATUS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case UPDATE_BUBBLE_STATUS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isUpdateBubbleStatusSuccess: action.payload.isSuccessful,
        loading: false,
      };
    case UPDATE_BUBBLE_STATUS_FAILURE:
      return {
        ...state,
        message: action.payload,
        isUpdateBubbleStatusSuccess: action.payload.isSuccessful,
        hasError: true,
        loading: false,
      };
    case CREATE_REPORT_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case CREATE_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
        isShowReportRequestModal: true,
      };
    case CREATE_REPORT_REQUEST_FAILURE:
      return {
        ...state,
        message: action.payload,
        isShowReportRequestModal: false,
        hasError: true,
        loading: false,
      };
    case GET_REPORT_LINK_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_REPORT_LINK_REQUEST_SUCCESS:
      return {
        ...state,
        reportLinkGenerated: action.payload,
        isShowExportLink: true,
        hasError: false,
        loading: false,
      };
    case GET_REPORT_LINK_REQUEST_FAILURE:
      return {
        ...state,
        reportLinkGenerated: action.payload,
        isShowExportLink: false,
        hasError: false,
        loading: false,
      };
    case CLOSE_REPORT_REQUEST_DIALOG:
      return {
        ...state,
        isShowReportRequestModal: false,
      };
    case CLOSE_REPORT_LINK_DIALOG:
      return {
        ...state,
        isShowExportLink: false,
      };
    case CLOSE_CANNOT_COPY_DALOG:
      return {
        ...state,
        showCannotCopyModal: false,
      };
    case CLOSE_COPY_NOT_COMPLIED_DIALOG:
      return {
        ...state,
        showCopyNotCompliedModal: false,
      };
    case OPEN_CANNOT_COPY_DIALOG:
      return {
        ...state,
        showCannotCopyModal: true,
      };
    case OPEN_COPY_NOT_COMPLIED_DIALOG:
      return {
        ...state,
        showCopyNotCompliedModal: true,
      };
    case OPEN_EXISTING_ITEM_IN_CART_DIALOG:
      return {
        ...state,
        showExistingItemInCartModal: true,
      };
    case CLOSE_EXISTING_ITEM_IN_CART_DIALOG:
      return {
        ...state,
        showExistingItemInCartModal: false,
      };
    case GET_CART_AND_ORDER_DETAILS:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case GET_CART_AND_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
        copyOrderCheckStatus: action.payload,
      };
    case GET_CART_AND_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
        message: action.payload,
      };

    case MANUAL_REPUSH:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case MANUAL_REPUSH_SUCCESS:
      return {
        ...state,
        hasError: false,
        isManualRepushFailed: action.payload.isSuccessful,
        loading: false,
      };
    case MANUAL_REPUSH_FAILURE:
      return {
        ...state,
        message: action.payload,

        hasError: true,
        loading: false,
      };

    case OPEN_AUTO_SWITCH_PSN_ROLE_DIALOG:
      return {
        ...state,
        showAutoSwitchPsnRoleDialog: true,
        autoSwitchRoleData: action.payload,
      };

    case CLOSE_AUTO_SWITCH_PSN_ROLE_DIALOG:
      return {
        ...state,
        showAutoSwitchPsnRoleDialog: false,
      };
    case CLEAR_FILTER_TRIGGER:
      return {
        ...state,
        isClearFilterToggled: true,
      };
    case CLEAR_FILTER_TRIGGER_RESET:
      return {
        ...state,
        isClearFilterToggled: false,
      };
    case DISABLE_CLEAR_FILTER:
      return {
        ...state,
        isClearFilterDisabled: action.payload.toggle,
      };
      case IS_VALID_COPY_ORDER_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case IS_VALID_COPY_ORDER_SUCCESS:
      const results = {
        ...state,
        hasError: false,
        isValidCopyOrder: action.payload.data,
        loading: false,
      };

      return results;
    case IS_VALID_COPY_ORDER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default viewOrdersReducer;
