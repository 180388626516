import {
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
  ORDER_HISTORY_FAILURE,
  ORDER_SELECT_ALL,
  ORDER_SELECT,
  ORDER_CHANGE_PAGE,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAILURE,
  GET_FILE_NUMBERS_REQUEST,
  GET_FILE_NUMBERS_SUCCESS,
  GET_FILE_NUMBERS_FAILURE,
  ORDER_HISTORY_FORM,
  ORDER_HISTORY_CLEAR,
  GET_APPLICANTS_REQUEST,
  GET_APPLICANTS_SUCCESS,
  GET_APPLICANTS_FAILURE,
  GET_MANUFACTURERS_REQUEST,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_FAILURE,
  GET_ORACLE_NUMBERS_REQUEST,
  GET_ORACLE_NUMBERS_SUCCESS,
  GET_ORACLE_NUMBERS_FAILURE,
  GET_REQUEST_NUMBERS_REQUEST,
  GET_REQUEST_NUMBERS_SUCCESS,
  GET_REQUEST_NUMBERS_FAILURE,
  SET_USER_FILTER_REQUEST,
  SET_USER_FILTER_SUCCESS,
  GET_USER_FILTER_REQUEST,
  GET_USER_FILTER_SUCCESS,
  GET_USER_FILTER_FAILURE,
  FILTEROPTION_RESET,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_LABEL_CENTERS_REQUEST,
  GET_LABEL_CENTERS_SUCCESS,
  GET_LABEL_CENTERS_FAILURE,
  GET_LABEL_APPLICANT_ACCOUNTNO_REQUEST,
  GET_LABEL_APPLICANT_ACCOUNTNO_SUCCESS,
  GET_LABEL_APPLICANT_ACCOUNTNO_FAILURE,
  GET_LABEL_MANUFACTURER_ACCOUNTNO_REQUEST,
  GET_LABEL_MANUFACTURER_ACCOUNTNO_SUCCESS,
  GET_LABEL_MANUFACTURER_ACCOUNTNO_FAILURE,
  ORDER_HISTORY_UPDATE,
  UPLOAD_LABEL_ORDER_ATTACHMENT_SUCCESS,
  UPLOAD_LABEL_ORDER_ATTACHMENT_FAILURE,
  UPLOAD_LABEL_ORDER_ATTACHMENT_REQUEST,
  DELETE_LABEL_ORDER_ATTACHMENT_SUCCESS,
  DELETE_LABEL_ORDER_ATTACHMENT_FAILURE,
  DELETE_LABEL_ORDER_ATTACHMENT_REQUEST,
  GET_LABEL_ORDER_ATTACHMENT_REQUEST,
  GET_LABEL_ORDER_ATTACHMENT_FAILURE,
  GET_LABEL_ORDER_ATTACHMENT_SUCCESS,
  SEND_ATTACHMENT_MESSAGE_REQUEST,
  SEND_ATTACHMENT_MESSAGE_FAILURE,
  SEND_ATTACHMENT_MESSAGE_SUCCESS,
  GET_CUSTOMER_PO_NUMBER_REQUEST,
  GET_CUSTOMER_PO_NUMBER_FAILURE,
  GET_CUSTOMER_PO_NUMBER_SUCCESS,
  GET_CUSTOMER_CONTACT_NAME_REQUEST,
  GET_CUSTOMER_CONTACT_NAME_FAILURE,
  GET_CUSTOMER_CONTACT_NAME_SUCCESS,
  GET_APPLICANT_ADDRESS_REQUEST,
  GET_APPLICANT_ADDRESS_FAILURE,
  GET_APPLICANT_ADDRESS_SUCCESS,
  GET_MANUFACTURER_ADDRESS_REQUEST,
  GET_MANUFACTURER_ADDRESS_FAILURE,
  GET_MANUFACTURER_ADDRESS_SUCCESS,
  GET_MANUFACTURER_PSN_REQUEST,
  GET_MANUFACTURER_PSN_FAILURE,
  GET_MANUFACTURER_PSN_SUCCESS,
  UPDATE_BUBBLE_STATUS_REQUEST,
  UPDATE_BUBBLE_STATUS_SUCCESS,
  UPDATE_BUBBLE_STATUS_FAILURE,
  CLEAR_EXPORT_SELECTION,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_REQUEST_FAILURE,
  CREATE_REPORT_REQUEST_SUCCESS,
  GET_REPORT_LINK_REQUEST,
  GET_REPORT_LINK_REQUEST_SUCCESS,
  GET_REPORT_LINK_REQUEST_FAILURE,
  CLOSE_REPORT_REQUEST_DIALOG,
  CLOSE_REPORT_LINK_DIALOG,
  CLOSE_CANNOT_COPY_DALOG,
  CLOSE_COPY_NOT_COMPLIED_DIALOG,
  OPEN_CANNOT_COPY_DIALOG,
  OPEN_COPY_NOT_COMPLIED_DIALOG,
  OPEN_EXISTING_ITEM_IN_CART_DIALOG,
  CLOSE_EXISTING_ITEM_IN_CART_DIALOG,
  GET_CART_AND_ORDER_DETAILS,
  GET_CART_AND_ORDER_DETAILS_SUCCESS,
  GET_CART_AND_ORDER_DETAILS_FAILURE,
  MANUAL_REPUSH,
  MANUAL_REPUSH_SUCCESS,
  MANUAL_REPUSH_FAILURE,
  OPEN_AUTO_SWITCH_PSN_ROLE_DIALOG,
  CLOSE_AUTO_SWITCH_PSN_ROLE_DIALOG,
  CLEAR_FILTER_TRIGGER,
  CLEAR_FILTER_TRIGGER_RESET,
  DISABLE_CLEAR_FILTER,
  IS_VALID_COPY_ORDER_REQUEST,
  IS_VALID_COPY_ORDER_FAILURE,
  IS_VALID_COPY_ORDER_SUCCESS,
} from './view-orders.type';
import api from '../../services/api';

const orderHistoryRequest = () => {
  return { type: ORDER_HISTORY_REQUEST };
};

const orderHistoryFailure = error => {
  return dispatch => dispatch({ type: ORDER_HISTORY_FAILURE, payload: error });
};

const orderHistorySuccess = response => {
  return dispatch =>
    dispatch({ type: ORDER_HISTORY_SUCCESS, payload: response });
};
const orderDetailsRequest = () => {
  return { type: ORDER_DETAILS_REQUEST };
};

const orderDetailsFailure = error => {
  return dispatch => dispatch({ type: ORDER_DETAILS_FAILURE, payload: error });
};

const orderDetailsSuccess = response => {
  return dispatch =>
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: response });
};

export const selectAll = isSelectedAll => {
  return { type: ORDER_SELECT_ALL, payload: { isSelectedAll } };
};

export const select = (data, isSelected) => {
  return { type: ORDER_SELECT, payload: { data, isSelected } };
};

export const clearExportSelection = () => {
  return { type: CLEAR_EXPORT_SELECTION };
};

export const changePageByUser = (activePage, pageSize) => {
  return async dispatch => {
    dispatch({ type: ORDER_CHANGE_PAGE, payload: { activePage } });
    await api.Order.getOrdersByUser(activePage, pageSize)
      .then(result => {
        dispatch(orderHistorySuccess(result));
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch(orderHistoryFailure(errors));
      });
  };
};

export const getOrdersByUser = (currentPage, pageSize, startDate, endDate) => {
  return async dispatch => {
    dispatch({ type: ORDER_CHANGE_PAGE, payload: { currentPage } });
    dispatch(orderHistoryRequest());
    await api.Order.getOrdersByUser(currentPage, pageSize, startDate, endDate)
      .then(result => {
        dispatch(orderHistorySuccess(result));
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch(orderHistoryFailure(errors));
      });
  };
};

export const exportOrderList = selectedOrderNumbers => {
  return async dispatch => {
    return await api.Order.exportOrderListCenter({
      selectedOrderNumbers,
    });
  };
};

export const exportOrderListByUser = selectedOrderNumbers => {
  return async dispatch => {
    return await api.Order.exportOrderListUser({
      selectedOrderNumbers,
    });
  };
};

export const getOrders = (currentPage, pageSize) => {
  return async dispatch => {
    dispatch({ type: ORDER_CHANGE_PAGE, payload: { currentPage } });
    dispatch({ type: GET_ORDER_REQUEST });
    await api.Order.getOrders(currentPage, pageSize)
      .then(result => {
        dispatch({ type: GET_ORDER_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_ORDER_FAILURE, payload: errors });
      });
  };
};

export const getOrdersByUserFilter = data => {
  return async dispatch => {
    dispatch(orderHistoryRequest());
    await api.Order.getOrdersByUserFilter(data)
      .then(async results => {
        dispatch(orderHistorySuccess(results));
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch(orderHistoryFailure(errors));
      });
  };
};

export const setUserFilter = data => {
  return async dispatch => {
    dispatch({ type: SET_USER_FILTER_REQUEST });
    await api.Order.setUserFilter(data)
      .then(result => {
        dispatch({ type: SET_USER_FILTER_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: SET_USER_FILTER_SUCCESS, payload: errors });
      });
  };
};

export const getUserFilter = () => {
  return async dispatch => {
    dispatch({ type: GET_USER_FILTER_REQUEST });
    await api.Order.getUserFilter()
      .then(result => {
        dispatch({ type: GET_USER_FILTER_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_USER_FILTER_FAILURE, payload: errors });
      });
  };
};

export const getOrderByRequestNumber = requestNumber => {
  return async dispatch => {
    dispatch(orderDetailsRequest());
    await api.Order.getOrderByRequestNumber(requestNumber)
      .then(result => {
        dispatch(orderDetailsSuccess(result));
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch(orderDetailsFailure(errors));
      });
  };
};

export const getListFileNumbers = () => {
  return async dispatch => {
    dispatch({ type: GET_FILE_NUMBERS_REQUEST });
    await api.Order.getListFileNumbers()
      .then(result => {
        dispatch({ type: GET_FILE_NUMBERS_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_FILE_NUMBERS_FAILURE, payload: errors });
      });
  };
};

export const getListApplicants = userId => {
  return async dispatch => {
    dispatch({ type: GET_APPLICANTS_REQUEST });
    await api.Order.getListApplicants(userId)
      .then(result => {
        dispatch({ type: GET_APPLICANTS_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_APPLICANTS_FAILURE, payload: errors });
      });
  };
};

export const getListManufacturers = userId => {
  return async dispatch => {
    dispatch({ type: GET_MANUFACTURERS_REQUEST });
    await api.Order.getListManufacturers(userId)
      .then(result => {
        dispatch({ type: GET_MANUFACTURERS_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_MANUFACTURERS_FAILURE, payload: errors });
      });
  };
};

export const getListOracleNumbers = userId => {
  return async dispatch => {
    dispatch({ type: GET_ORACLE_NUMBERS_REQUEST });
    await api.Order.getListOracleNumbers(userId)
      .then(result => {
        dispatch({ type: GET_ORACLE_NUMBERS_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_ORACLE_NUMBERS_FAILURE, payload: errors });
      });
  };
};

export const getListRequestNumbers = userId => {
  return async dispatch => {
    dispatch({ type: GET_REQUEST_NUMBERS_REQUEST });
    await api.Order.getListRequestNumbers(userId)
      .then(result => {
        dispatch({ type: GET_REQUEST_NUMBERS_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_REQUEST_NUMBERS_FAILURE, payload: errors });
      });
  };
};

export const getListLabelCenters = () => {
  return async dispatch => {
    dispatch({ type: GET_LABEL_CENTERS_REQUEST });
    await api.Order.getListLabelCenters()
      .then(result => {
        dispatch({ type: GET_LABEL_CENTERS_SUCCESS, payload: result });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({ type: GET_LABEL_CENTERS_FAILURE, payload: errors });
      });
  };
};

export const getListApplicantAccountNos = userId => {
  return async dispatch => {
    dispatch({ type: GET_LABEL_APPLICANT_ACCOUNTNO_REQUEST });
    await api.Order.getListApplicantAccountNos(userId)
      .then(result => {
        dispatch({
          type: GET_LABEL_APPLICANT_ACCOUNTNO_SUCCESS,
          payload: result,
        });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({
          type: GET_LABEL_APPLICANT_ACCOUNTNO_FAILURE,
          payload: errors,
        });
      });
  };
};

export const getListManufacturerAccountNos = userId => {
  return async dispatch => {
    dispatch({ type: GET_LABEL_MANUFACTURER_ACCOUNTNO_REQUEST });
    await api.Order.getListManufacturerAccountNos(userId)
      .then(result => {
        dispatch({
          type: GET_LABEL_MANUFACTURER_ACCOUNTNO_SUCCESS,
          payload: result,
        });
      })
      .catch(result => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map(e => e);
        }
        dispatch({
          type: GET_LABEL_MANUFACTURER_ACCOUNTNO_FAILURE,
          payload: errors,
        });
      });
  };
};

export const uploadLabelOrderAttachment = data => {
  return async dispatch => {
    dispatch({ type: UPLOAD_LABEL_ORDER_ATTACHMENT_REQUEST });
    const result = await api.Order.uploadLabelOrderAttachment(data);
    if (result) {
      dispatch({
        type: UPLOAD_LABEL_ORDER_ATTACHMENT_SUCCESS,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: UPLOAD_LABEL_ORDER_ATTACHMENT_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const getLabelOrderAttachment = (labelOrderId, labelOrderType) => {
  return async dispatch => {
    dispatch({ type: GET_LABEL_ORDER_ATTACHMENT_REQUEST });
    await api.Order.getLabelOrderAttachment(labelOrderId, labelOrderType)
      .then(result => {
        dispatch({ type: GET_LABEL_ORDER_ATTACHMENT_SUCCESS, payload: result });
      })
      .catch(result => {
        dispatch({ type: GET_LABEL_ORDER_ATTACHMENT_FAILURE, payload: result });
      });
  };
};

export const deleteLabelOrderAttachment = (
  attachmentId,
  labelOrderType,
  isCombinationArtwork
) => {
  return async dispatch => {
    dispatch({ type: DELETE_LABEL_ORDER_ATTACHMENT_REQUEST });
    const result = await api.Order.deleteLabelOrderAttachment(
      attachmentId,
      labelOrderType,
      isCombinationArtwork
    );
    if (result) {
      dispatch({
        type: DELETE_LABEL_ORDER_ATTACHMENT_SUCCESS,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: DELETE_LABEL_ORDER_ATTACHMENT_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const sendAttachmentMessage = data => {
  return async dispatch => {
    dispatch({ type: SEND_ATTACHMENT_MESSAGE_REQUEST });
    const result = await api.Order.sendAttachmentMessage(data);
    if (result) {
      dispatch({ type: SEND_ATTACHMENT_MESSAGE_SUCCESS, payload: result });
      return result;
    } else {
      dispatch({
        type: SEND_ATTACHMENT_MESSAGE_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const getCustomerPoNumber = userId => {
  return async dispatch => {
    dispatch({ type: GET_CUSTOMER_PO_NUMBER_REQUEST });
    await api.Order.getListCustomerPoNumber(userId)
      .then(result => {
        dispatch({ type: GET_CUSTOMER_PO_NUMBER_SUCCESS, payload: result });
      })
      .catch(result => {
        dispatch({ type: GET_CUSTOMER_PO_NUMBER_FAILURE, payload: result });
      });
  };
};

export const getCustomerContactName = userId => {
  return async dispatch => {
    dispatch({ type: GET_CUSTOMER_CONTACT_NAME_REQUEST });
    await api.Order.getListCustomerContactName(userId)
      .then(result => {
        dispatch({ type: GET_CUSTOMER_CONTACT_NAME_SUCCESS, payload: result });
      })
      .catch(result => {
        dispatch({ type: GET_CUSTOMER_CONTACT_NAME_FAILURE, payload: result });
      });
  };
};

export const getListApplicantAddress = () => {
  return async dispatch => {
    dispatch({ type: GET_APPLICANT_ADDRESS_REQUEST });
    await api.Order.getListApplicantAddress()
      .then(result => {
        dispatch({ type: GET_APPLICANT_ADDRESS_SUCCESS, payload: result });
      })
      .catch(result => {
        dispatch({ type: GET_APPLICANT_ADDRESS_FAILURE, payload: result });
      });
  };
};

export const getListManufacturerAddress = () => {
  return async dispatch => {
    dispatch({ type: GET_MANUFACTURER_ADDRESS_REQUEST });
    await api.Order.getListManufacturerAddress()
      .then(result => {
        dispatch({ type: GET_MANUFACTURER_ADDRESS_SUCCESS, payload: result });
      })
      .catch(result => {
        dispatch({ type: GET_MANUFACTURER_ADDRESS_FAILURE, payload: result });
      });
  };
};

export const getListManufacturerPsn = () => {
  return async dispatch => {
    dispatch({ type: GET_MANUFACTURER_PSN_REQUEST });
    await api.Order.getListManufacturerPsn()
      .then(result => {
        dispatch({ type: GET_MANUFACTURER_PSN_SUCCESS, payload: result });
      })
      .catch(result => {
        dispatch({ type: GET_MANUFACTURER_PSN_FAILURE, payload: result });
      });
  };
};

export const updateBubbleStatus = data => {
  return async dispatch => {
    dispatch({ type: UPDATE_BUBBLE_STATUS_REQUEST });
    const result = await api.Order.updateBubbleStatus(data);
    if (result) {
      dispatch({ type: UPDATE_BUBBLE_STATUS_SUCCESS, payload: result });
      return result;
    } else {
      dispatch({
        type: UPDATE_BUBBLE_STATUS_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const filterOptionReset = () => {
  return dispatch => dispatch({ type: FILTEROPTION_RESET });
};

export const updateData = result => {
  return dispatch => dispatch({ type: ORDER_HISTORY_UPDATE, payload: result });
};

export const updateForm = result => {
  return dispatch => dispatch({ type: ORDER_HISTORY_FORM, payload: result });
};

export const clearForm = () => dispatch =>
  dispatch({ type: ORDER_HISTORY_CLEAR });

export const createReportRequest = data => {
  return async dispatch => {
    dispatch({ type: CREATE_REPORT_REQUEST });
    const result = await api.Order.createReportRequest(data);
    if (result) {
      dispatch({
        type: CREATE_REPORT_REQUEST_SUCCESS,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: CREATE_REPORT_REQUEST_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const manualRepushOrder = data => {
  return async dispatch => {
    dispatch({ type: MANUAL_REPUSH });
    const result = await api.Order.manualRepushOrder(data);
    if (result) {
      dispatch({
        type: MANUAL_REPUSH_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: MANUAL_REPUSH_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const getReportLink = () => {
  return async dispatch => {
    dispatch({ type: GET_REPORT_LINK_REQUEST });
    const result = await api.Order.getExportLink();
    if (result) {
      dispatch({
        type: GET_REPORT_LINK_REQUEST_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({
        type: GET_REPORT_LINK_REQUEST_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const closeReportRequestDialog = () => dispatch =>
  dispatch({ type: CLOSE_REPORT_REQUEST_DIALOG });

export const closeReportLinkDialog = () => dispatch =>
  dispatch({ type: CLOSE_REPORT_LINK_DIALOG });

export const closeCannotCopyDialog = () => dispatch =>
  dispatch({ type: CLOSE_CANNOT_COPY_DALOG });

export const closeCopyNotCompliedDialog = () => dispatch =>
  dispatch({ type: CLOSE_COPY_NOT_COMPLIED_DIALOG });

export const openCannotCopyDialog = () => dispatch =>
  dispatch({ type: OPEN_CANNOT_COPY_DIALOG });

export const openCopyNotCompliedDialog = () => dispatch =>
  dispatch({ type: OPEN_COPY_NOT_COMPLIED_DIALOG });

export const openExistingItemInCartDialog = () => dispatch =>
  dispatch({ type: OPEN_EXISTING_ITEM_IN_CART_DIALOG });

export const closeExistingItemInCartDialog = () => dispatch =>
  dispatch({ type: CLOSE_EXISTING_ITEM_IN_CART_DIALOG });

export const checkCartAndOrderDetails = (userRole, labelOrderId) => {
  return async dispatch => {
    dispatch({ type: GET_CART_AND_ORDER_DETAILS });
    const result = await api.Order.checkCartAndOrderDetails(
      userRole,
      labelOrderId
    );
    if (result) {
      dispatch({
        type: GET_CART_AND_ORDER_DETAILS_SUCCESS,
        payload: result.data,
      });
      return result;
    } else {
      dispatch({
        type: GET_CART_AND_ORDER_DETAILS_FAILURE,
        payload: 'Something went wrong.',
      });
    }
  };
};

export const openAutoSwitchPsnRoleDialog = data => dispatch =>
  dispatch({ type: OPEN_AUTO_SWITCH_PSN_ROLE_DIALOG, payload: data });

export const closeAutoSwitchPsnRoleDialog = () => dispatch =>
  dispatch({ type: CLOSE_AUTO_SWITCH_PSN_ROLE_DIALOG });

export const clearFilter = () => dispatch =>
  dispatch({ type: CLEAR_FILTER_TRIGGER });

export const resetClearFilter = () => dispatch =>
  dispatch({ type: CLEAR_FILTER_TRIGGER_RESET });

export const disableClearFilter = toggle => dispatch =>
  dispatch({ type: DISABLE_CLEAR_FILTER, payload: { toggle } });

export const isValidCopyOrder = (ccn, fileNumber,
  volume,
  companyName,
  address,
  country,
  userRole) => {
  return async dispatch => {
    dispatch({ type: IS_VALID_COPY_ORDER_REQUEST });
    const result = await api.Order.isValidCopyOrder(ccn, fileNumber,
      volume,
      companyName,
      address,
      country,
      userRole);
      if(result) {
        dispatch({ type: IS_VALID_COPY_ORDER_SUCCESS, payload: result });
        return result;
      }
      else {
        dispatch({
          type: GET_CART_AND_ORDER_DETAILS_FAILURE,
          payload: 'Something went wrong.',
        });
      }
  };
};
